import React from "react";
import { useQuery } from "@tanstack/react-query";
import {
  getAOV,
  getBundleProduct,
  getBundleProductsCustomer,
  getCollectionProducts,
  getCollections,
  getCollectionsChartData,
  getFrequentCustomer,
  getOrderComparison,
  getProductsByType,
  getProductsGraphData,
  getProductsSpan,
  getProductsViews,
  getProductTypes,
  getRegionalOrders,
  getRevenueComparison,
  getROIData,
  getSellingMonth,
  getSkuData,
  getStocksVariantData,
  getStore,
  getSyncProgressStats,
  getValueableCustomer,
} from "../utils/Queries.js";

//general data hooks
export const useSyncStoreProgress = () => {
  return useQuery({
    queryKey: ["progress"],
    queryFn: () => getSyncProgressStats(),
  });
};

export const useStore = ({ onSuccess, onError, manual }) => {
  return useQuery({
    queryKey: ["store"],
    queryFn: () => getStore(),
    onSuccess: (data) => {
      onSuccess(data);
    },
    onError: (error) => {
      onError(error);
    },
    manual,
    refetchInterval: 10000,
  });
};
export const useAOV = ({ startDate, endDate }) => {
  return useQuery({
    queryKey: ["aov", { startDate, endDate }],
    queryFn: () => getAOV({ startDate, endDate }),
    keepPreviousData: true,
  });
};

//customers hooks
export const useFrequentCustomers = ({
  startDate,
  endDate,
  limit,
  page = 1,
}) => {
  return useQuery({
    queryKey: ["frequentCustomer", { startDate, endDate, limit, page }],
    queryFn: () => getFrequentCustomer({ startDate, endDate, limit, page }),
    keepPreviousData: true,
  });
};

export const useValueableCustomers = ({
  startDate,
  endDate,
  limit,
  page = 1,
  sortBy = "revenue",
}) => {
  return useQuery({
    queryKey: [
      "valueableCustomers",
      { startDate, endDate, limit, page, sortBy },
    ],
    queryFn: () =>
      getValueableCustomer({ startDate, endDate, limit, page, sortBy }),
    keepPreviousData: true,
  });
};

//product hooks
export const useGetProductsSpan = ({
  region,
  state,
  startDate,
  endDate,
  page,
  perPage,
  type = "",
}) => {
  return useQuery({
    queryKey: [
      "getProductsSpan",
      { region, state, startDate, endDate, page, perPage, type },
    ],
    queryFn: () =>
      type
        ? getProductsByType({
            region,
            state,
            startDate,
            endDate,
            page,
            perPage,
            type,
          })
        : getProductsSpan({ region, state, startDate, endDate, page, perPage }),
    keepPreviousData: true,
  });
};
export const useGetProductsByTypes = ({
  region,
  state,
  startDate,
  endDate,
  page,
  perPage,
  type,
}) => {
  return useQuery({
    queryKey: [
      "getProductsByType",
      { region, state, startDate, endDate, page, perPage, type },
    ],
    queryFn: () =>
      getProductsByType({
        region,
        state,
        startDate,
        endDate,
        page,
        perPage,
        type,
      }),
    keepPreviousData: true,
  });
};

export const useGetProductTypes = ({
  region,
  state,
  startDate,
  endDate,
  page,
  perPage,
}) => {
  return useQuery({
    queryKey: [
      "getProductTypes",
      { region, state, startDate, endDate, page, perPage },
    ],
    queryFn: () =>
      getProductTypes({ region, state, startDate, endDate, page, perPage }),
    keepPreviousData: true,
  });
};
export const useGetProductsGraph = ({ region, startDate, endDate }) => {
  return useQuery({
    queryKey: ["getProductsGraph", { region, startDate, endDate }],
    queryFn: () => getProductsGraphData({ region, startDate, endDate }),
  });
};

//regional data hooks
export const useGetRegionalOrders = ({
  limit,
  startDate,
  endDate,
  dashboard,
  region,
  state,
  page,
  perPage,
}) => {
  return useQuery({
    queryKey: [
      "getRegionalOrders",
      { limit, startDate, endDate, dashboard, region, state, page, perPage },
    ],
    queryFn: () =>
      getRegionalOrders({
        limit,
        startDate,
        endDate,
        dashboard,
        region,
        state,
        page,
        perPage,
      }),
    keepPreviousData: true,
  });
};

//collections hooks
export const useGetCollections = ({
  region,
  state,
  startDate,
  endDate,
  page,
  perPage,
}) => {
  return useQuery({
    queryKey: [
      "getCollections",
      { region, state, startDate, endDate, page, perPage },
    ],
    queryFn: () =>
      getCollections({ region, state, startDate, endDate, page, perPage }),
    keepPreviousData: true,
  });
};
export const useGetCollectionProducts = ({ id, page }) => {
  return useQuery({
    queryKey: ["getCollectionProducts", { id, page }],
    queryFn: () => getCollectionProducts({ id, page }),
    keepPreviousData: true,
  });
};
export const useGetCollectionsChart = ({
  collections,
  region,
  state,
  startDate,
  endDate,
}) => {
  return useQuery({
    queryKey: [
      "getCollectionsGraph",
      { collections, region, state, startDate, endDate },
    ],
    queryFn: () =>
      getCollectionsChartData({
        collections,
        region,
        state,
        startDate,
        endDate,
      }),
    keepPreviousData: true,
  });
};

//sku

export const useGetSkuData = ({
  region,
  state,
  startDate,
  endDate,
  page,
  perPage,
}) => {
  return useQuery({
    queryKey: [
      "getSkuData",
      { region, state, startDate, endDate, page, perPage },
    ],
    queryFn: () =>
      getSkuData({ region, state, startDate, endDate, page, perPage }),
    keepPreviousData: true,
  });
};

export const useGetROIData = ({
  region,
  state,
  startDate,
  endDate,
  page,
  perPage,
}) => {
  return useQuery({
    queryKey: [
      "geROIData",
      { region, state, startDate, endDate, page, perPage },
    ],
    queryFn: () =>
      getROIData({ region, state, startDate, endDate, page, perPage }),
    keepPreviousData: true,
  });
};

//bundles
export const useGetBundleProduct = ({ startDate, endDate }) => {
  return useQuery({
    queryKey: ["getBundleProduct", { startDate, endDate }],
    queryFn: () => getBundleProduct({ startDate, endDate }),
    keepPreviousData: true,
  });
};

export const useGetBundleProductsCustomer = ({ startDate, endDate }) => {
  return useQuery({
    queryKey: ["getBundleProductsCustomer", { startDate, endDate }],
    queryFn: () => getBundleProductsCustomer({ startDate, endDate }),
    keepPreviousData: true,
  });
};

//stocks and wherehouses

export const useGetStocksVariantData = ({ page, onSuccess }) => {
  return useQuery({
    queryKey: ["getStocksVariantData", { page }],
    queryFn: () => getStocksVariantData({ page }),
    keepPreviousData: true,
    onSuccess: (data) => {
      onSuccess(data.data);
    },
  });
};

export const useGetSellingMonth = ({ sortBy, enabled }) => {
  return useQuery({
    queryKey: ["getSellingMonth", { sortBy }],
    queryFn: () => getSellingMonth({ sortBy }),
    keepPreviousData: true,
    enabled,
  });
};

export const useOrderComparison = ({ month, city, state }) => {
  return useQuery({
    queryKey: ["OrderComparison", { month, city, state }],
    queryFn: () => getOrderComparison({ month, city, state }),
    keepPreviousData: true,
  });
};
export const useRevenueComparison = ({ month, city, state }) => {
  return useQuery({
    queryKey: ["RevenueComparison", { month, city, state }],
    queryFn: () => getRevenueComparison({ month, city, state }),
    keepPreviousData: true,
  });
};

export const useGetProductViewed = ({
  region,
  state,
  startDate,
  endDate,
  page,
  perPage = 20,
}) => {
  return useQuery({
    queryKey: ["getProductViewed", { page }],
    queryFn: () =>
      getProductsViews({ region, state, startDate, endDate, page, perPage }),
    keepPreviousData: true,
  });
};
