import React from "react";
import { ReactSVG } from "react-svg";

const CustomPlanCard = ({
  name,
  desc,
  items = [
    "All analytics features",
    "Normal support",
    "1 year data will be used for analytics",
  ],
}) => {
  return (
    <div className="payment-card-main">
      <div>
        <h1 className="payment-card-name">{name}</h1>
        <h1 className="payment-card-desc">{desc}</h1>
        <h1 className="current-payment-card-price"></h1>
        <h1 className="payment-card-second-heading">What’s included</h1>
        {items.map((item, index) => (
          <div key={index} className="flex mb-4 items-center">
            <ReactSVG src="/assets/blue-check.svg" />{" "}
            <h1 className="payment-card-items">{item}</h1>
          </div>
        ))}
      </div>

      <a
        href="mailto:info@selli.solutions"
        className="special-plan-card-button text-white hover:text-white"
        target="_blank"
      >
        Email Us
      </a>
    </div>
  );
};

export default CustomPlanCard;
