import React from "react";
import { Row, Col, Layout, Image } from "antd";
import { ReactSVG } from "react-svg";
import moment from "moment";
import MyAvatar from "../assets/images/avatar.png";
const { Header, Sider, Content } = Layout;

export const MainHeader = (props) => {
	return (
		<Header className="cu-header">
			<Row className="row-cus-align-center">
				<Col span={8}>
					<ReactSVG src="/assets/selli-logo.svg" />
				</Col>
				<Col span={8} offset={8} style={{ textAlign: "right" }}>
					<div className="cusRelative">
						<div className="cuStoreName">
							<span className="store_name">{props.storeName}</span>
							<img
								width="20"
								height="20"
								src="https://img.icons8.com/external-yogi-aprelliyanto-basic-outline-yogi-aprelliyanto/40/external-store-retail-store-yogi-aprelliyanto-basic-outline-yogi-aprelliyanto.png"
								alt="store"
							/>
						</div>
						<div className="para cuLastSync">
							<p className="lastSyncText">
								Last Sync:{" "}
								<span className="date">
									{moment(props.lastSync || "").format("llll")}
								</span>
							</p>
						</div>
					</div>
				</Col>
			</Row>
		</Header>
	);
};
