import React from "react";

const Loader = ({ widht = 5, height = 5, color = "border-green-500" }) => {
	return (
		<div className="h-screen flex flex-col justify-center items-center">
			<div className="ball"></div>
			<div className="shadow"></div>
			<div id="load">
				<div className="fontSize">G</div>
				<div className="fontSize">N</div>
				<div className="fontSize">I</div>
				<div className="fontSize">D</div>
				<div className="fontSize">A</div>
				<div className="fontSize">O</div>
				<div className="fontSize">L</div>
			</div>
		</div>
	);
};

export default Loader;
