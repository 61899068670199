import { Button, Checkbox, Drawer, Layout, Menu, Space, Spin } from "antd";
import moment from "moment";
import React, {
  Children,
  Suspense,
  useContext,
  useEffect,
  useState,
} from "react";
import { Link, Route, Routes, useSearchParams } from "react-router-dom";
import StoreStatus from "../components/StoreStatus";
import { MyContext } from "../ContextApi/MyContext";
import AllRoutes from "../routes/index";
import { NavItems } from "../utils/constantObjects";
import { notificationError, notificationSuccess } from "../utils/helper";
import {
  confirmPayment,
  getAllProductTypes,
  getDateRangeLimit,
  getStore,
  getStoreCities,
  getStoreProducts,
  getSyncProgressStats,
  getVendor,
  useGetStore,
  useStoreQuery,
} from "../utils/Queries";

import { MainHeader } from "../components/Header";
import { ReactSVG } from "react-svg";
import SideModal from "../components/modal/SideModal";
import { useStore } from "../hooks";
import Loader from "../components/Loader";
import Uninstalled from "../components/Uninstalled";
import PaymentCancelled from "../components/PaymentCancelled";
import TrialExpired from "../components/TrialExpired";
import Plan from "../pages/plan/index.js";
const { Content, Sider } = Layout;

const Index = () => {
  const onChange = (e) => {
    console.log(`checked = ${e.target.value}`);
  };
  const showDrawer = () => {
    setOpenSideModal(true);
  };
  const onClose = () => {
    setOpenSideModal(false);
  };
  const [searchParams, setSearchParams] = useSearchParams();

  let token = searchParams.get("token");

  const [isLoading, setIsLoading] = useState(true);
  const [localroutes, setLocalroutes] = useState([]);
  const [defaultOpen, setDefaultOpen] = useState("");
  const [vendor, setVendor] = useState([]);
  const [productTypes, setProductTypes] = useState([]);
  const [storeProducts, setStoreProducts] = useState([]);
  const [upgradeButtonPressed, setUpgradeButtonPressed] = useState(false);
  // const { status, data: storeData, error, isFetching } = useGetStore();

  const {
    setStoreData,
    currentPage,
    setCurrentPage,
    setCities,
    setMaxStartDate,
    setSyncDataStats,
    syncDataStats,
    openSideModal,
    setOpenSideModal,
  } = useContext(MyContext);

  const handleNav = (e) => {
    if (!e.key == "/settings" || e.key == "/") setCurrentPage(e.key);
  };

  const {
    error: storeError,
    data: storeData,
    isFetching: isGettingStore,
    refetch: refetchStore,
  } = useStore({
    onSuccess: async (data) => {
      setStoreData(data);
      const filteredRoutes = AllRoutes.filter(({ Plan }) =>
        Plan.includes(data?.StorePlan?.Plan?.name)
      );
      const routeComponents = filteredRoutes.map(({ path, component }, key) => (
        <Route exact path={path} element={component} key={key} />
      ));
      setLocalroutes(routeComponents);
      // getStoreData();
      await getVendorData();
      await getProductTypes();
      await getDateRangeLimitFunc();
      setIsLoading(false);
    },
    onError: (error) => {
      if (error.response.status == 401 && localStorage.getItem("accessToken")) {
        refetchStore();
      }
      setIsLoading(false);
    },
  });

  const getVendorData = async () => {
    try {
      const vendors = await getVendor();
      setVendor(vendors);
    } catch (error) {
      console.log("getVendor error", error);
    }
  };
  const getProductTypes = async () => {
    try {
      const productTypes = await getAllProductTypes();
      setProductTypes(productTypes);
      console.log("productTypes", productTypes);
    } catch (error) {
      console.log("getVendor error", error);
    }
  };

  const getProducts = async () => {
    try {
      const products = await getStoreProducts();
      setStoreProducts(products);
    } catch (error) {
      console.log("getVendor error", error);
    }
  };
  const getDateRangeLimitFunc = async () => {
    try {
      const resp = await getDateRangeLimit();
      setMaxStartDate(resp.startDate);
    } catch (err) {
      console.log(err);
      notificationError({
        description: "Failed to get date range limit",
      });
    }
  };
  const paymentConfirmation = async ({ charge_id }) => {
    try {
      const data = await confirmPayment({ charge_id });
      setStoreData(data?.store);
      notificationSuccess({ message: data.message });
    } catch (err) {
      notificationError({ message: err.message });
      console.log(err);
    }
  };
  // const getSyncProgressStateFunc = async () => {
  // 	try {
  // 		const resp = await getSyncProgressStats();
  // 		console.log(":::::resp", resp);
  // 		setSyncDataStats(resp);
  // 		// const repsToArr = Object.entries(resp);
  // 		// const allPercents = repsToArr.map((obj) =>
  // 		// 	Number(String(obj[1].percentage).split("%")[0])
  // 		// );

  // 		// const { order = {}, collections = {}, product = {} } = resp;

  // 		// const custmObj = {
  // 		// 	orders: order,
  // 		// 	collections: collections,
  // 		// 	products: product,
  // 		// };

  // 		// const minPercent = Math.min(...allPercents);
  // 		// minPercent < 95
  // 		// 	? setSyncDataStats((prev) => ({
  // 		// 			...prev,
  // 		// 			isCollectedAll: false,
  // 		// 			data: Object.entries(custmObj),
  // 		// 	  }))
  // 		// 	: setSyncDataStats((prev) => ({
  // 		// 			...prev,
  // 		// 			isCollectedAll: false,
  // 		// 			data: Object.entries(custmObj),
  // 		// 	  }));
  // 	} catch (err) {
  // 		console.log(err);
  // 		notificationError({
  // 			description: "Failed to get sync progress state",
  // 		});
  // 	}
  // };
  useEffect(() => {
    // getProducts();
    setIsLoading(true);
    if (window.location.search.split("charge_id=")[1]) {
      paymentConfirmation({
        charge_id: window.location.search.split("charge_id=")[1],
      });
      searchParams.delete("charge_id");
      setSearchParams(searchParams);
      setCurrentPage(window.location.pathname);
    }
    if (token) {
      localStorage.setItem("accessToken", token);
      searchParams.delete("token");
      setSearchParams(searchParams);
    }
    setCurrentPage(window.location.pathname);
    const defaultOpen = "/" + window.location.pathname.split("/")[1];
    setDefaultOpen(defaultOpen);

    // getSyncProgressStateFunc();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return isLoading ? (
    <Loader />
  ) : storeData?.uninstalled || !localStorage.getItem("accessToken") ? (
    <StoreStatus
      image={
        <ReactSVG style={{ paddingRight: 12 }} src="/assets/uninstall.svg" />
      }
      title="Hang tight and follow directions below!"
      paragraph={
        <p>
          If you’ve installed the app, please launch it via your store. Need
          assistance? Reach out to us at{" "}
          <a
            href="mailto:info@selli.solutions"
            target="_blank"
            className="underline hover:underline focus:underline"
          >
            info@selli.solutions
          </a>{" "}
          anytime!
        </p>
      }
    />
  ) : storeData?.StorePlan?.Plan?.name != "Free" &&
    storeData?.payment_status == "CANCELLED" ? (
    <StoreStatus
      image={
        <ReactSVG
          style={{ paddingRight: 12 }}
          src="/assets/payment-cancel.svg"
        />
      }
      title="Payment Failed"
      paragraph={`Sorry, your payment was failed for this month.
Please try again later or contact Selli support. Once the payment is done your app will automatically start working.`}
    />
  ) : storeData?.StorePlan?.Plan?.name != "Free" &&
    (storeData?.payment_status == "" ||
      storeData?.payment_status == "DECLINED") ? (
    <Plan />
  ) : // 			: !isGettingStore &&
  //   storeData?.StorePlan?.Plan?.name == "Free" &&
  //   storeData?.trial_expired ? (
  // 	<Suspense
  // 		fallback={
  // 			<div className="flex flex-col items-center justify-center h-screen"></div>
  // 		}
  // 	>
  // 		{window.location.pathname.includes("plans") || upgradeButtonPressed ? (
  // 			<Plans />
  // 		) : (
  // 			<TrialExpired setUpgradeButtonPressed={setUpgradeButtonPressed} />
  // 		)}
  // 	</Suspense>
  // 			)
  !storeData?.data_fetched ? (
    <StoreStatus
      image={
        <>
          <Spin size="large" />
          <br />
          <p className="subscription-card-loading-text">Loading...</p>
        </>
      }
      title=""
      paragraph={`Hi, We are fetching your data from Selli. Once the data is fetched we will send you an email on ${storeData.shop_email}. It can take a while your patience is required.`}
    />
  ) : (
    <Layout>
      <MainHeader
        storeName={String(storeData?.store_url || "")
          .split(".myshopify")[0]
          .replace(/-/g, " ")}
        lastSync={String(storeData?.last_sync)}
      />

      {/* <Header>
				<Link to="/">
					<h2
						className={`text-white text-2xl leading-10 mb-0 capitalize ${
							syncDataStats.isCollectedAll ? "mt-3" : ""
						}`}
					>
						{String(storeData?.store_url || "")
							.split(".myshopify")[0]
							.replace(/-/g, " ")}
					</h2>
				</Link>
			</Header> */}
      <Layout>
        <Sider width={300} className="min-h-screen side-navigation-main-div">
          <Menu
            onClick={handleNav}
            mode="inline"
            selectedKeys={[currentPage]}
            defaultSelectedKeys={["/"]}
            defaultOpenKeys={[defaultOpen]}
            items={NavItems({ onSettingPress: showDrawer, storeData })
              .filter(({ Plan }) =>
                Plan.includes(storeData?.StorePlan?.Plan?.name)
              )
              .map((item) => {
                return {
                  ...item,
                  children: item?.children?.filter(({ Plan }) =>
                    Plan.includes(storeData?.StorePlan?.Plan?.name)
                  ),
                };
              })}
            className="side-bar-menu"
          />
        </Sider>
        {openSideModal && (
          <Drawer
            width={500}
            size="default"
            title=""
            placement="right"
            closable={false}
            onClose={onClose}
            visible={openSideModal}
          >
            <SideModal
              products={storeProducts}
              vendors={vendor}
              productTypes={productTypes.productTypes}
              onClose={onClose}
              onChange={onChange}
            />
          </Drawer>
        )}

        <Content className="p-4">
          <Suspense
            fallback={
              <div className="flex flex-col items-center justify-center h-screen"></div>
            }
          >
            <Routes>{localroutes}</Routes>
          </Suspense>
        </Content>
      </Layout>
    </Layout>
  );
};

export default Index;
