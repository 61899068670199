import React, { useContext } from "react";
import { CheckOutlined } from "@ant-design/icons";
import { MyContext } from "../ContextApi/MyContext";
import Loader from "./Loader";
import LoadingPaymentCards from "./LoadingPaymentCards";
import { ReactSVG } from "react-svg";

const PaymentPlanCards = ({
	isLoading,
	id,
	name,
	price,
	desc,
	items,
	purchasePlan,
}) => {
	const { storeData } = useContext(MyContext);
	return (
		<div
			className={
				storeData?.StorePlan?.purchased
					? "current-payment-card-main"
					: "payment-card-main"
			}
		>
			<div>
				<h1
					className={
						storeData?.StorePlan?.purchased
							? "current-payment-card-name"
							: "payment-card-name"
					}
				>
					{name}
				</h1>
				<h1
					className={
						storeData?.StorePlan?.purchased
							? "current-payment-card-desc"
							: "payment-card-desc"
					}
				>
					{desc}
				</h1>
				<h1
					className={
						storeData?.StorePlan?.purchased
							? "current-payment-card-price"
							: "payment-card-price"
					}
				>
					{price}
					<span
						className={
							storeData?.StorePlan?.purchased
								? "current-payment-card-period"
								: "payment-card-period"
						}
					>
						/month
					</span>
				</h1>
				<h1
					className={
						storeData?.StorePlan?.purchased
							? "current-payment-card-second-heading"
							: "payment-card-second-heading"
					}
				>
					What’s included
				</h1>
				{items.map((item, index) => (
					<div key={index} className="flex mb-4 items-center">
						<ReactSVG
							src={
								storeData?.StorePlan?.purchased
									? "/assets/white-check.svg"
									: "/assets/blue-check.svg"
							}
						/>{" "}
						<h1
							className={
								storeData?.StorePlan?.purchased
									? "current-payment-card-items"
									: "payment-card-items"
							}
						>
							{item}
						</h1>
					</div>
				))}
			</div>

			<button
				onClick={() => purchasePlan({ id })}
				className={
					storeData?.StorePlan?.purchased
						? "current-payment-card-button"
						: "payment-card-button"
				}
				disabled={storeData?.StorePlan?.purchased}
			>
				{storeData?.StorePlan?.purchased ? "Current" : "Subscribe"}
			</button>
		</div>
	);
};

export default PaymentPlanCards;
