import { Spin } from "antd";
import React from "react";

const StoreStatus = ({ image, title, paragraph }) => {
	return (
		<div class="subscription-card">
			<div class="inner">
				<div class="card-image">{image}</div>
				<div class="card-text">
					<h2 class="title">{title}</h2>
					<p class="paragraph">{paragraph}</p>
				</div>
			</div>
		</div>
		// <div className="flex flex-row h-screen justify-center text-center items-center bg-blue-500">
		// 	<div className="w-4/12 bg-white leading px-20 py-32 rounded">
		// 		<div>
		// 			{/* <Spin size="Large" /> */}
		// 			<img src="https://assets-global.website-files.com/5c7fdbdd4e3feeee8dd96dd2/6134707265a929f4cdfc1f6d_5.gif" />
		// 			<p className="text-xl">
		// 				{" "}
		// 				Hi, We are fetching your data from shopify. Once the data is fetched
		// 				we will send you an email on <strong>{store?.shop_email}</strong>.
		// 				It can take a while your patience is required
		// 			</p>
		// 		</div>
		// 	</div>
		// </div>
	);
};

export default StoreStatus;
