import moment from "moment";
import React, { useState, createContext } from "react";
export const MyContext = createContext();

export const MyProvider = ({ children }) => {
  const [cities, setCities] = useState([]);

  const [storeData, setStoreData] = useState({});
  const [currentPage, setCurrentPage] = useState("");
  const [syncDataStats, setSyncDataStats] = useState({
    isCollectedAll: false,
    data: [],
  });
  const [maxStartDate, setMaxStartDate] = useState("");

  const dateRangeDiff = [moment().subtract(1, "M"), moment()];

  const [dateRange, setDateRange] = useState(dateRangeDiff);
  const [region, setRegion] = useState("");
  const [state, setState] = useState("");
  const [viewState, setViewState] = useState("");
  const [viewCity, setViewCity] = useState("");
  const [viewDateRange, setViewDateRange] = useState(dateRangeDiff);
  const [type, setType] = useState("");
  const [openSideModal, setOpenSideModal] = useState(false);

  // ==================ROI=====================
  // ==================ROI=====================

  // ==================SKU=====================
  // ==================ROI=====================

  // ==================TopProduct=====================
  // ==================TopProduct=====================

  // ==================Regional=====================
  // ==================Regional=====================

  // ==================Collection=====================
  // ==================Collection=====================

  // ==================ValuableCus=====================
  // ==================ValuableCus=====================

  // ==================FreqCus=====================
  // ==================FreqCus=====================

  // ==================BundleBought=====================
  // ==================BundleBought=====================

  // ==================BundleCustomer=====================
  // ==================BundleCustomer=====================

  return (
    <MyContext.Provider
      value={{
        dateRangeDiff,
        dateRange,
        setDateRange,
        region,
        setRegion,
        storeData,
        state,
        setState,
        setStoreData,
        currentPage,
        setCurrentPage,
        cities,
        setCities,
        syncDataStats,
        setSyncDataStats,
        maxStartDate,
        setMaxStartDate,
        openSideModal,
        setOpenSideModal,
        type,
        setType,
        viewState,
        setViewState,
        viewCity,
        setViewCity,
        viewDateRange,
        setViewDateRange,
      }}
    >
      {children}
    </MyContext.Provider>
  );
};
