import "./App.css";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import Layout from "./layout";
import "antd/dist/antd.min.css";
import { MyProvider } from "./ContextApi/MyContext";
import { Button, Drawer } from "antd";
import { useEffect, useState } from "react";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { useSearchParams } from "react-router-dom";

const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			refetchOnWindowFocus: false,
			refetchOnmount: false,
			refetchOnReconnect: false,
			retry: false,
			staleTime: 12 * (60 * 60 * 1000), // 12 hours
			cacheTime: 24 * (60 * 60 * 1000), //
		},
	},
});
function App() {
	const [searchParams, setSearchParams] = useSearchParams();

	let token = searchParams.get("token");

	useEffect(() => {
		if (token) {
			localStorage.setItem("accessToken", token);
			searchParams.delete("token");
			setSearchParams(searchParams);
		}
	}, []);

	return (
		<QueryClientProvider client={queryClient}>
			<MyProvider>
				<Layout />
			</MyProvider>
			{/* <ReactQueryDevtools initialIsOpen={false} /> */}
		</QueryClientProvider>
	);
}

export default App;
