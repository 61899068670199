import axios from "axios";

const Apicall = ({ url, method, data, header = {}, params }) => {
	return new Promise((resolve, reject) => {
		axios({
			url: `${process.env.REACT_APP_BACKEND_URL}${url}`,
			method,
			data,
			headers: {
				...header,
				"Content-Type": "application/json",
				accessToken:
					localStorage.getItem("accessToken") != null
						? "Bearer " + localStorage.getItem("accessToken")
						: null,
				// accessToken:
				// 	"Bearer eyJhbGciOiJIUzI1NiJ9.dGVzdC1odXNzbmFpbi1zdG9yZS5teXNob3BpZnkuY29t.sKW3VWhzjgpYVxO-AJLJzAKuX6C9V5HELeG4q0XX2Jo",
			},
			params,
		})
			.then((res) => {
				resolve(res);
			})
			.catch((err) => {
				reject(err);
			});
	});
};

export default Apicall;
