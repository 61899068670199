import { Checkbox, Spin, Switch, Select } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { ReactSVG } from "react-svg";
import { MyContext } from "../../ContextApi/MyContext";
import { notificationError, notificationSuccess } from "../../utils/helper";
import { setConfiguration, setVendor } from "../../utils/Queries";
import { useQueryClient } from "@tanstack/react-query";
const SideModal = (props) => {
	const queryClient = useQueryClient();
	const { setStoreData, storeData } = useContext(MyContext);
	const [vendors, setVendors] = useState([]);
	const [productTypes, setProductTypes] = useState([]);
	const [loading, setLoading] = useState(false);
	const [comparison, setComparison] = useState([]);
	const [isZeroProductChecked, setIsZeroProductChecked] = useState(false);
	const onChangeSwitch = (checked) => {
		setIsZeroProductChecked(checked);
	};
	const onAddProductType = (value) => {
		console.log("value", value);
		setProductTypes(value);
	};
	const onChange = (data) => {
		const target = data.target.value;
		let newVendors = [...vendors];

		if (newVendors.includes(target)) {
			newVendors = newVendors.filter((v) => v != target);
		} else {
			newVendors.push(target);
		}

		setVendors(newVendors);
	};

	const comparisonFunction = ({ arr1, arr2 }) => {
		const checksArray = [];

		if (arr1.length != arr2.length) {
			return false;
		} else {
			arr1.forEach((item) => checksArray.push(arr2.includes(item)));
		}
		return checksArray.includes(false) ? false : true;
	};

	const onSave = async () => {
		try {
			setLoading(true);
			const data = await setVendor({ data: { vendors } });
			const data2 = await setConfiguration({
				data: {
					hide_products: isZeroProductChecked,
					hidden_product_type: productTypes,
				},
			});
			console.log(data);
			setStoreData(data.storeData);
			setLoading(false);
			queryClient.invalidateQueries();
			props.onClose();
			notificationSuccess({ message: data2.message });
		} catch (error) {
			setLoading(false);
			notificationError({ message: error.message });
		}
	};

	useEffect(() => {
		setVendors(storeData.vendors);
		setComparison(storeData.vendors);
		setIsZeroProductChecked(storeData.hide_zero_price_products);
		setProductTypes(storeData.hidden_product_type);
	}, []);
	return (
		<div className="flex flex-col justify-between h-full">
			<div>
				<div className="side-modal-main">
					<ReactSVG
						onClick={props.onClose}
						className="mr-2"
						src="/assets/white-cross.svg"
					/>
					<h1 className="side-modal-title">Settings</h1>
				</div>
				<div className="p-12">
					<h1 className="side-modal-vendortext">Does include below vendors?</h1>
					<h1 className="side-modal-vendorHeading">Vendors</h1>
					<div className="side-modal-checkbox-container">
						{props?.vendors?.map((vendor, index) => (
							<div key={index} className="w-1/2">
								<Checkbox
									value={vendor}
									onChange={onChange}
									checked={!vendors?.includes(vendor)}
								>
									{vendor}
								</Checkbox>
							</div>
						))}
					</div>

					<div className="side-modal-product-container justify-between side-modal-checkbox-container">
						<h1 className="side-modal-vendortext">
							Hide zero price products from your analysis
						</h1>
						<Switch checked={isZeroProductChecked} onChange={onChangeSwitch} />
					</div>
					<div>
						<h1 className="side-modal-vendortext">
							Product type that you don't want to show in results
						</h1>
						<Select
							mode="multiple"
							style={{
								width: "100%",
							}}
							placeholder="Please select your target product type"
							defaultValue={storeData?.hidden_product_type.map((item) => ({
								value: item,
								label: item,
							}))}
							onChange={onAddProductType}
							options={props.productTypes.map((item) => ({
								value: item,
								label: item,
							}))}
						/>
					</div>
				</div>
			</div>
			<div className="side-modal-footer">
				<button onClick={props.onClose} className="cancel-btn">
					Cancel
				</button>
				<button
					onClick={onSave}
					className={
						loading ||
						(comparisonFunction({ arr1: comparison, arr2: vendors }) &&
							isZeroProductChecked == storeData?.hide_zero_price_products &&
							comparisonFunction({
								arr1: productTypes,
								arr2: storeData?.hidden_product_type,
							}))
							? "save-btn-disabled"
							: "save-btn"
					}
					disabled={
						loading ||
						(comparisonFunction({ arr1: comparison, arr2: vendors }) &&
							isZeroProductChecked == storeData?.hide_zero_price_products &&
							comparisonFunction({
								arr1: productTypes,
								arr2: storeData?.hidden_product_type,
							}))
					}
				>
					{loading ? <Spin size="small" /> : "Save"}
				</button>
			</div>
		</div>
	);
};

export default SideModal;
