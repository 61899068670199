import React from "react";

const SubscriptionAlert = () => {
	return (
		<div class="subscription-alert">
			<div class="image">
				<svg
					xmlns="http://www.w3.org/2000/svg"
					width="32"
					height="32"
					viewBox="0 0 32 32"
					fill="none"
				>
					<g clip-path="url(#clip0_1302_1346)">
						<path
							d="M16 0C7.16429 0 0 7.16429 0 16C0 24.8357 7.16429 32 16 32C24.8357 32 32 24.8357 32 16C32 7.16429 24.8357 0 16 0ZM17.1429 23.7143C17.1429 23.8714 17.0143 24 16.8571 24H15.1429C14.9857 24 14.8571 23.8714 14.8571 23.7143V14C14.8571 13.8429 14.9857 13.7143 15.1429 13.7143H16.8571C17.0143 13.7143 17.1429 13.8429 17.1429 14V23.7143ZM16 11.4286C15.5514 11.4194 15.1243 11.2348 14.8103 10.9143C14.4962 10.5938 14.3204 10.163 14.3204 9.71429C14.3204 9.2656 14.4962 8.83478 14.8103 8.51429C15.1243 8.19379 15.5514 8.00916 16 8C16.4486 8.00916 16.8757 8.19379 17.1898 8.51429C17.5038 8.83478 17.6796 9.2656 17.6796 9.71429C17.6796 10.163 17.5038 10.5938 17.1898 10.9143C16.8757 11.2348 16.4486 11.4194 16 11.4286Z"
							fill="#1890FF"
						/>
					</g>
					<defs>
						<clipPath id="clip0_1302_1346">
							<rect width="32" height="32" fill="white" />
						</clipPath>
					</defs>
				</svg>
			</div>
			<div class="text">
				<p class="heading">Subscription Alert</p>
				<p class="small-text">
					You need to subscribe this plan to use the app.
				</p>
			</div>
		</div>
	);
};

export default SubscriptionAlert;
