import React, { useContext, useEffect, useState } from "react";
import PaymentPlanCards from "../../components/PaymentPlanCards";
import { MyContext } from "../../ContextApi/MyContext";
import { notificationError, notificationSuccess } from "../../utils/helper";
import {
  confirmPayment,
  getAllPlans,
  getCountOfAllOrders,
  makePayment,
} from "../../utils/Queries";
import { useSearchParams } from "react-router-dom";
import { Spin } from "antd";
import SubscriptionAlert from "../../components/subscriptionAlert";
import CustomPlanCard from "../../components/CustomPlanCard";

const Index = () => {
  const { setCurrentPage, storeData, setStoreData } = useContext(MyContext);
  const [searchParams, setSearchParams] = useSearchParams();
  const [loading, setLoading] = useState(false);

  const purchasePlan = async ({ id }) => {
    try {
      setLoading(true);

      const { appSubscriptionCreate } = await makePayment({ id });
      console.log(
        "appSubscriptionCreate",
        appSubscriptionCreate.confirmationUrl
      );
      window.location.href = appSubscriptionCreate.confirmationUrl;
    } catch (err) {
      setLoading(false);

      notificationError({ message: err.message });
    }
  };

  return loading ? (
    <div className="flex justify-center items-center h-screen">
      <Spin size="large" />
    </div>
  ) : (
    <div className="flex items-center flex-col pt-14 ">
      <SubscriptionAlert />

      <div className="flex">
        <PaymentPlanCards
          id={storeData?.StorePlan?.Plan._id}
          name={storeData?.StorePlan?.Plan.name}
          price={
            storeData?.StorePlan?.Plan.name == "Custom" //Change
              ? storeData?.StorePlan?.price
              : storeData?.StorePlan?.Plan.price
          }
          desc={storeData?.StorePlan?.Plan.desc}
          items={storeData?.StorePlan?.Plan.items}
          purchasePlan={purchasePlan}
        />
        {storeData?.StorePlan?.Plan.name !== "Custom" ? (
          <CustomPlanCard
            name={"Special Pricing"}
            desc={"Email us for special plan"}
          />
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default Index;
